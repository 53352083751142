@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap");

* {
  font-family: "Nunito", sans-serif;
}

.nunitoFont {
  font-family: "Nunito", sans-serif;
}

.pointer {
  cursor: pointer;
}

.offcanvas{
  max-width: 90% !important;
}
.monoFont {
  font-family: "JetBrains Mono", monospace;
}

.bgGradient {
  background: linear-gradient(
    0deg,
    rgba(241, 44, 24, 0.44) 0%,
    rgba(241, 44, 24, 0.44) 100%
  ),
  linear-gradient(
    113deg,
    #f12c18 1.06%,
    #ff9d29 29.15%,
    #f12c18 75.21%,
    #ff9d29 93.17%
  );
}

.my-custom-pagination2 .custom-pagination-bullet {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #000;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.my-custom-pagination2 .custom-pagination-bullet.active {
  background-color: #f12c17;
}

p {
  margin-bottom: 0;
}

#heroSection {
  background-image: url("../img/season1/bgImage.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.greyColor {
  color: #7b7b7b;
}

.mainColor {
  color: #f12c17;
}

.mainBgColor {
  background-color: #f12c17;
}

.secondaryColor {
  color: #ff9d29;
}

.secondaryBgColor {
  background-color: #ff9d29;
}

body {
  background-color: #e8e8da;
}

.floating-image {
  animation: float 2.5s ease-in-out infinite;
}

#countdown {
  display: flex;
  align-items: center;
  gap: 10px;
}

.boxShadow {
  box-shadow: 10px 10px 0px 0px #000;
}


@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

#wenFat{
  background-image: url("../img/wen.png");
  object-fit:fill;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 290px;
}

.swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: visible; /* Permite que los elementos sobresalgan */
}

.swiper-navigation {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: calc(
    100% + 40px
  ); /* Ajusta para permitir que los botones sobresalgan */
  left: -20px; /* Centra los botones en los bordes */
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 9999999; /* Asegura que la navegación esté por encima */
}

.custom-prev,
.custom-next {
  pointer-events: all;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999999; /* Asegura visibilidad */
}

.custom-prev {
  left: 0; /* Ajusta para que sobresalga correctamente */
}

.custom-next {
  right: 0; /* Ajusta para que sobresalga correctamente */
}

@media (width >= 640px) {
  #seasonCard1{
    position: absolute !important;
    top: -95px;
    right: 120px;
  }

  #believe{
    width: 80% !important;
  }
}
#team{
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 4rem;
}
@media (width <= 840px) {

  #seasonHeroImg {
    max-width: 210px !important;
  }

  #eatTitle {
    font-size: 30px !important;
  }
}

@media (width <= 640px) {
  #presaleSection {
    margin-top: 150px !important;
  }

  #mainTitle{
    font-size: 40px !important;
    margin-top: 5px;
  }

  #coin{
    top: -40px;
    right: 30px;
    width: 90px;
  }

  .subsection {
    height: 400px !important;
  }

  #howSection {
    margin-top: 80px !important;
  }

  #testimonialSection {
    margin-top: 50px !important;
  }

  #swiperSection {
    margin-top: 50px !important;
  }

  #backedSection {
    margin-top: 50px !important;
  }

  .swiper-navigation img {
    width: 33px !important;
  }

  .swiper-navigation {
    left: -9px;
    width: calc(100% + 15px);
  }
  #seasonHeroImg {
    max-width: 250px !important;
  }

  #hero1 {
    margin: 150px 50px !important;
  }

  #eatSection {
    width: 100% !important;
  }

  #eatTitle {
    font-size: 35px !important;
  }

  #eatSubtitle {
    font-size: 17px !important;
    padding: 0px 20px;
    margin-top: 35px !important;
  }

  #heroSection {
    height: 650px !important;
    border-radius: 20px 20px 20px 20px !important;
  }

  #countdown p {
    font-size: 30px !important;
  }

  #countdown p span {
    font-size: 20px !important;
  }

  #cashBack {
    font-size: 30px !important;
  }

  #floatingCard {
    max-width: 325px !important;
  }

  .floatingDivCard {
    margin-top: 285px !important;
  }
}

@media (width <= 500px) {
  #hero1{
    margin: 150px 10px !important;
  }

  #wenFat {
    background-image: none !important;
    height: 370px !important;
    max-height: 370px !important;
  }

  #seasonSection{
    margin-top: 70px !important;
  }

  #unlock{
    width: 100% !important;
  }

  #seasonCard1{
    width: 100% !important;
  }
}
